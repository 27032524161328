<template>
  <v-container fluid>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          REPORTES DE PRODUCTO
        </h3>
      </v-card-title>
      <v-spacer></v-spacer>

    </v-card>
    <v-row >
      <v-col cols="100" sm="30"  md="4">
        <v-container >
          <v-card
              max-width="350"
              class="mx-auto"
          >
            <v-list two-line>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-laptop-chromebook
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Total de ventas por caja</v-list-item-title>
                  <v-list-item-subtitle>
                    Responsable y observaciones</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-btn
                      rounded
                      color="primary"
                      dark
                      @click="getReportSalesCashesPersons(user[0].area.entity.id)"
                  >
                    DESCARGAR
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-human-greeting
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Ventas por cajero</v-list-item-title>
                  <v-list-item-subtitle>
                    Responsable y observaciones</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-btn
                      rounded
                      color="primary"
                      dark
                      @click="getReportSalesMonthPersonal(user[0].area.entity.id)"
                  >
                    DESCARGAR
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

            </v-list>

          </v-card>
        </v-container>
      </v-col>

      <v-col>
        <template>
          <v-card
              class="mx-auto text-center"
              color="blue"
              dark
              max-width="1200"
          >
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                    :value="value"
                    color="rgba(255, 255, 255, .7)"
                    height="120"
                    padding="25"
                    stroke-linecap="round"
                    smooth
                >
                  <template v-slot:label="item">
                    ${{ item.value }}
                  </template>

                </v-sparkline>
              </v-sheet>
            </v-card-text>

            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Total ventas de los ultimos 7 meses
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-center">
              <v-btn
                  block
                  text
                  @click="getReportSalesMonth(user[0].area.entity.id)"
              >
                Venta de los ultimos meses
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
import PDF from 'jspdf'
import 'jspdf-autotable';


export default {
  name: "Inventory",
  data: () => ({
    value: [ 9, 5, 10, 3, 5, 8, 9],
  }),

  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {

    getReportSalesCashesPersons (id) {
      setTimeout(() => {
        axios.get(`api/report/salesCashesPersons/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal

              this.createPDFSalesCashesPersons()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsSalesCashesPersons() {
      return [{
        caja: 'NUMERO DE CAJA',
        firstName: 'CAJERO',
        fechaApertura: 'FECHA DE APERTURA',
        montoApertura: 'MONTO INICIAL',
        fechaCierre: 'FECHA DE CIERRE',
        montoCierre: 'MONTO FINAL',
        observaciones: 'OBSERVACIONES',
        areaAsignada: 'AREA RESPONSABLE',
      }];
    },
    bodyRowsSalesCashesPersons(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          caja: pdfBody[i].caja,
          firstName: pdfBody[i].firstName +' '+ pdfBody[i].lastName,
          fechaApertura: pdfBody[i].fechaApertura,
          montoApertura: pdfBody[i].montoApertura,
          fechaCierre: pdfBody[i].fechaCierre,
          montoCierre: pdfBody[i].montoCierre,
          observaciones: pdfBody[i].observaciones,
          areaAsignada: pdfBody[i].areaAsignada,
        });
      }
      return body;
    },
    createPDFSalesCashesPersons () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'TOTAL DE VENTAS POR CAJA')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);
          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)
        };
        doc.autoTable({didDrawPage : header_6});
      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsSalesCashesPersons(),
        body: this.bodyRowsSalesCashesPersons(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 40, valign: 'middle', fontSize: 10, halign: 'center', fontStyle: 'bold'},
          1: {cellWidth: 90, valign: 'middle',halign: 'left'},
          2: {cellWidth: 70, valign: 'middle',halign: 'left'},
          3: {cellWidth: 50, valign: 'middle', halign: 'center'},
          4: {cellWidth: 70, valign: 'middle',halign: 'left'},
          5: {cellWidth: 50, valign: 'middle', halign: 'center'},
          6: {cellWidth: 90, valign: 'middle', halign: 'left'},
          7: {cellWidth: 90, halign: 'left'},
        }
      });
      doc.save(`CajasPorPersonal${Date.now()}.pdf`);
    },


    getReportSalesMonthPersonal(id) {
      setTimeout(() => {
        axios.get(`api/report/salesMonthPersonal/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal

              this.createPDFSalesMonthPersonal()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsSalesMonthPersonal() {
      return [{
        month: 'MES',
        md: 'FECHA',
        firstName: 'RESPONSABLE',
        total: 'MONTO TOTAL',
      }];
    },
    bodyRowsSalesMonthPersonal(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          month: pdfBody[i].month,
          md: pdfBody[i].md,
          firstName: pdfBody[i].firstName +' '+ pdfBody[i].lastName,
          total: pdfBody[i].total,
        });
      }
      return body;
    },
    createPDFSalesMonthPersonal() {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'TOTAL DE VENTAS POR CAJA')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 11,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsSalesMonthPersonal(),
        body: this.bodyRowsSalesMonthPersonal(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 11,
        },
        columnStyles: {
          0: {cellWidth: 50, valign: 'middle', fontSize: 10, halign: 'center', fontStyle: 'bold'},
          1: {cellWidth: 80, valign: 'middle',halign: 'center'},
          2: {cellWidth: 250, valign: 'middle',halign: 'left'},
          3: {cellWidth: 80, valign: 'middle', halign: 'right'},
        }
      });

      doc.save(`CajasPorPersonal${Date.now()}.pdf`);
    },


    getReportSalesMonth(id) {
      setTimeout(() => {
        axios.get(`api/report/salesMonth/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              this.createPDFSalesMonth()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsSalesMonth() {
      return [{
        month: 'MES',
        md: 'FECHA',
        total: 'MONTO TOTAL',
      }];
    },
    bodyRowsSalesMonth(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          month: pdfBody[i].month,
          md: pdfBody[i].md,
          total: pdfBody[i].total,
        });
      }
      return body;
    },
    createPDFSalesMonth() {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'TOTAL DE VENTAS POR MES')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 11,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsSalesMonth(),
        body: this.bodyRowsSalesMonth(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 11,
        },
        columnStyles: {
          0: {cellWidth: 100, valign: 'middle', fontSize: 10, halign: 'center', fontStyle: 'bold'},
          1: {cellWidth: 150, valign: 'middle',halign: 'center'},
          3: {cellWidth: 150, valign: 'middle', halign: 'right'},
        }
      });
      doc.save(`VentasPorMes${Date.now()}.pdf`);
    },



  }
}
</script>
